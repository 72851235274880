import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import LoginAuth from '../services/auth.service';

const getAccessToken = () => {
  const admoneCookie = Cookies.get('admone');
  if (admoneCookie) {
  const tokens = JSON.parse(admoneCookie);
  return tokens.accessToken;
  }
  return null;
};

const getRefreshToken = () => {
  const admoneCookie = Cookies.get('admone');
  if (admoneCookie) {
  const tokens = JSON.parse(admoneCookie);
  return tokens.refreshToken;
  }
  return null;
};






const ProtectedRoutes = () => {
  const location = useLocation();
  let token = getAccessToken();
  let Refreshtoken = getRefreshToken();
  const isAuthenticated = !!token




const checkAuthentication = async () => {
 

const data={
  refreshToken:Refreshtoken
}
      if (!token &&Refreshtoken ) {
        // Token is not available, try refreshing it
        try{
          const refreshedData = await LoginAuth.RefreshToken(data);
      
        
          if (refreshedData && refreshedData.data.token.access.token) {
            // token =refreshedData.data.token.access.token; // Set the new token
            return <Navigate to="/dashboard" />;
          }
        }catch(err){
          console.log(err)
          return <Navigate to="/login" />
        }
       
      }

   
    };

  

   if(!Refreshtoken){
    return <Navigate to="/login" />
   }



if (!isAuthenticated) {


   checkAuthentication()


    // Redirect to login page if not authenticated
    // return <Navigate to="/login" />;
  }
if(location.pathname === '/'){
  return <Navigate to={`/dashboard`} />
}
  return <Outlet />;
};

export default ProtectedRoutes;
