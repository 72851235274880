import React from "react";

const BreadcrumbNavigation = ({ folderPath, handleResetPath, handleBreadcrumbClick }) => {
  // Check if the first element is "Home" to avoid duplication
  const isHomeAlreadyIncluded = folderPath.length > 0 && folderPath[0].name === "Home";

  return (
    <nav className="breadcrumb mb-3">
      {/* Root Breadcrumb */}
      {!isHomeAlreadyIncluded && (
        <span role="button" onClick={handleResetPath} className="breadcrumb-item">
        Trashed Files
        </span>
      )}
      
      {/* Dynamic Breadcrumbs */}
      {folderPath.map((folder, index) => (
        <span
          key={folder.id}
          role="button"
          className="breadcrumb-item"
          onClick={() => handleBreadcrumbClick(index)}
        >
          {folder.name}
        </span>
      ))}
    </nav>
  );
};

export default BreadcrumbNavigation;
