// src/utils/icons.js
import tasksIcon from '../assets/images/tasks_icon.svg'
import unreadIcon from '../assets/images/unread_icon.svg'
import dashboardIcon from "../assets/images/dashboard_icon.svg";
import documentIcon from "../assets/images/document_icon.svg";
import dragIcon from "../assets/images/draginout_icon.png";
import chatIcon from "../assets/images/chats_icon.svg";
import chatIcon2 from "../assets/images/chat_icon.svg";
import fileIcon from "../assets/images/file_icon.svg";
import billingIcon from "../assets/images/billing_icon.svg";
import companydataIcon from "../assets/images/companydata_icon.svg";
import notificationIcon from "../assets/images/notifications_icon.svg"
import notificationUnreadIcon from "../assets/images/notifications_unread_icon.svg"
import showPasswordIcon from "../assets/images/showpwd_icon.svg"
import hidePasswordIcon from "../assets/images/hidepwd_icon.svg"
import expandIcon from "../assets/images/expandwindow_icon.svg"
import trashIcon from "../assets/images/trashIcon.svg"
import recoverIcon from "../assets/images/recover.svg"
import folderIcon from "../assets/images/folderIcon.png"




export {
    tasksIcon,
    unreadIcon,
    dashboardIcon,
    documentIcon,
    dragIcon,
    chatIcon,
    billingIcon,
    companydataIcon,
    notificationUnreadIcon,
    notificationIcon,
    showPasswordIcon,
    hidePasswordIcon,
    expandIcon,
    chatIcon2,
    fileIcon,
    trashIcon,
    recoverIcon,
    folderIcon
};
