import React, { useState } from "react";

import { getFileIcon } from "../../utils/fileIcon";
import { Table } from "react-bootstrap";

import { recoverIcon } from "../../utils/icons";
import { downloadFile } from "../../utils/downloadFiles";
import PreviewFileModal from "../../components/common/Filemanager_PreviewFileModal";
import Pagination from "../../components/common/Pagination";
import FilesService from "../../services/files.service";
import { useDispatch } from "react-redux";
import { setLoading } from "../../feature/loading.slice";
import { setMessage } from "../../feature/message.slice";
import messages from "../../utils/meesage";
const Documents_trashed = ({
  trashedFile,

  currentPage,
  TotalPage,
  currentSet,
  setCurrentSet,
  setcurrentPage,
  setSortDirection,
  setSortBy,
  sortBy,
  sortDirection,
  getTrashedFile
}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [fileBlob, setFileBlob] = useState(null);
  const [fileType, setFileType] = useState(null);
  const downloadExtensions = ["docx", "xlsx", "pptx"];
const dispatch = useDispatch();
  const handleShow = async (file) => {
    try {
      const res = await FilesService.filePreview(file.id);

      if (res) {
        const blob = res.data;
        const blobUrl = URL.createObjectURL(blob);
        setFileBlob(blobUrl);
        setFileType(res.headers["content-type"]);
        if (downloadExtensions.includes(file.file_type) && blobUrl) {
          downloadFile(blobUrl, file.file_name);
        } else {
          setShow(true);
        }
      } else {
        console.error("Failed to fetch file");
      }
    } catch (err) {
      setFileBlob(null);
      setShow(true);
      console.log(err);
    }
  };

  const handleSort = (column) => {
    const newDirection =
      sortBy === column && sortDirection === "ASC" ? "DESC" : "ASC";
    setSortBy(column);
    setSortDirection(newDirection);
  };

  const handleRecover = async (id) => {
    try {
      dispatch(setLoading(true));
      dispatch(setMessage(messages.loading));
      await FilesService.RecoverFile(id);
     
      getTrashedFile();
      dispatch(setMessage(messages.success.RECOVER_FILE));
    } catch (err) {
      console.log(err);
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 3000);
    }
  };

  return (
    <div className="documents_files">
      <div className="table-responsive">
        <Table hover>
          <thead>
            <tr className="">
              <th onClick={() => handleSort("file_name")}>
                Name{" "}
                {sortBy === "file_name" &&
                  (sortDirection === "ASC" ? "↑" : "↓")}
              </th>
              <th onClick={() => handleSort("uploaded_by")}>
                Uploaded by{" "}
                {sortBy === "uploaded_by" &&
                  (sortDirection === "ASC" ? "↑" : "↓")}
              </th>

              <th
                className="text-danger"
                onClick={() => handleSort("deleted_at")}
              >
                Deleted On{" "}
                {sortBy === "deleted_at" &&
                  (sortDirection === "ASC" ? "↑" : "↓")}
              </th>

              <th className="text-center">Recover</th>
            </tr>
          </thead>
          <tbody>
            {trashedFile &&
              trashedFile.map((trashedFile) => (
                <tr>
                  <td>
                    <div className="d-flex align-items-center">
                      {trashedFile.file_type && (
                        <span className="icon">
                          <img
                            src={getFileIcon(trashedFile.file_type)}
                            className="file-icon me-2"
                          />
                        </span>
                      )}
                      <span
                        role="button"
                        onClick={() => handleShow(trashedFile)}
                      >
                        {trashedFile.file_name}
                      </span>
                    </div>
                  </td>
                  <td>{trashedFile.uploaded_by}</td>

                  <td className="text-danger">{trashedFile.deleted_on}</td>

                  <td className="recover text-center">
                    <img
                      src={recoverIcon}
                      style={{ width: "18px" }}
                      alt=""
                      onClick={() => handleRecover(trashedFile.id)}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
      <div className="w-100 d-flex justify-content-end">
        <Pagination
          currentPage={currentPage}
          TotalPage={TotalPage}
          currentSet={currentSet}
          setCurrentSet={setCurrentSet}
          setcurrentPage={setcurrentPage}
        />
      </div>

      {show && (
        <PreviewFileModal
          show={show}
          handleClose={handleClose}
          fileUrl={fileBlob}
          fileType={fileType}
        />
      )}
    </div>
  );
};

export default Documents_trashed;
