import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { FaDownload } from "react-icons/fa"; // Import Font Awesome download icon

const PreviewFileModal = ({
  show,
  handleClose,
  fileUrl,
  approved,
  approvedFile,
  approvedShow,
  fileType,
  handleapprovedFile,
}) => {
 
  const [isRejected, setIsRejected] = useState(false);
  const [isApproved, setIsApproved] = useState(false);


  const handleReject = () => {
    handleapprovedFile("rejected");
    setIsRejected(true);
    setIsApproved(false); // Remove approval when rejected
  };

  const handleApprove = () => {
    handleapprovedFile("approved");
    setIsApproved(true);
    setIsRejected(false); // Remove rejection when approved
  };

  const downloadFile = async () => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = fileUrl.split("/").pop(); 
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); 
  };

  return (
    <Modal className="file_preview" show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton className="bg-dark text-white " style={{}} >
        <Modal.Title className="d-flex justify-content-between align-items-center w-100">
          <span>File Preview</span>
          {fileUrl && (
            <button onClick={downloadFile} className="btn btn-white btn-sm bg-dark text-white fw-bold">Download</button>
          )}
        </Modal.Title>
        {(approved || approvedShow) && (
          <div className="d-flex gap-2 me-3">
            {!isApproved && (
              <button
                type="button"
                className="btn btn-sm btn-danger"
                onClick={handleReject}
                disabled={isRejected} // Disable when rejected
              >
                Reject
              </button>
            )}
            {!isRejected && (
              <button
                type="button"
                className="btn btn-sm btn-success"
                onClick={handleApprove}
                disabled={isApproved} // Disable when approved
              >
                Approve
              </button>
            )}
          </div>
        )}
        
      </Modal.Header>
      <Modal.Body>
        {fileUrl ? (
          <>
         
         {fileType === 'application/pdf' && (
           <iframe src={fileUrl} width="100%" height="600px" title="File Viewer" />
         )}

        
         {fileType.startsWith('image/') && (
           <img src={fileUrl} alt="File Preview" width="100%" height="auto" />
         )}

       </>
        ) : (
          <div style={{ textAlign: "center", padding: "20px", color: "red" }}>
            <h5>File not found</h5>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default PreviewFileModal;
