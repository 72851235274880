import React, { useEffect, useState } from "react";
import Default from "../../components/layouts/default";
import { Modal } from "react-bootstrap";
import Documents_files from "./Documents_files";
import Documents_trashed from "./Documents_trashed";
import Document_file_Upload from "./Document_file_Upload";
import FilesService from "../../services/files.service";
import View_Approved from "./View_Approved";
import { useDispatch, useSelector } from "react-redux";
import BreadcrumbNavigation from "../../components/common/BreadcrumbNavigation";

import {
  appendToFolderPath,
  removeFromFolderPath,
  resetFolderPath,
  setFolderPath,
} from "../../feature/folderPath.slice";
import { useLocation, useNavigate } from "react-router-dom";

const Documents = () => {
  const [showUploadFile, setUploadFile] = useState(false);
  const [showViewFile, setViewFile] = useState(false);
  const [files, setfiles] = useState([]);
  const [rootFolder, setrootFolder] = useState([]);
  const [searchKeyword, setsearchKeyword] = useState("");
  const [selectedFileUrl, setSelectedFileUrl] = useState(null);
  const [dataLoad, setdataLoad] = useState(true);
  const [showTrashed, setShowTrashed] = useState(false); // State to toggle between components
  const [sortBy, setSortBy] = useState("created_at"); // Default sorting column
  const [sortDirection, setSortDirection] = useState("desc");
  const [trashedFile, settrashedFile] = useState([]);
  const [clientId, setclientId] = useState(null);

  const currentFolderId = useSelector((state) => state.folder.currentFolderId);
  const location = useLocation();
  const navigate = useNavigate();
  const folderPath = useSelector((state) => state.folder.folderPath);
  const dispatch = useDispatch();
  const clientDetails = useSelector((state) => state.client.client);
  const uploadFilehandleClose = () => setUploadFile(false);
  const uploadFilehandleShow = () => setUploadFile(true);
  const uploadViewhandleClose = () => setViewFile(false);

  const handleChangeSearch = async (e) => {
    const keyword = e.target.value;
    setsearchKeyword(keyword);

    try {
      if (keyword.trim() === "") {
        getFiles(currentFolderId, null);
        return;
      }

      const Searchresponse = await FilesService.FilterFileFolder({
        searchKeyword: keyword,
        folderId: currentFolderId,
      });

      if (Searchresponse) {
        setfiles(Searchresponse);
        setdataLoad(false);
        const resolveFolderPath = (path) => {
          // For folders, exclude the last entry (current folder itself)
          return path && path.length > 1 ? path.slice(0, -1) : path;
        };

        // Extract paths from the response
        const firstFilePath = Searchresponse.files.data?.[0]?.path || [];
        const firstFolderPath =
          Searchresponse.subfolders.data?.[0]?.path?.length > 0
            ? resolveFolderPath(Searchresponse.subfolders.data[0].path)
            : firstFilePath; // Use file path if no folders found

        // Update Redux folder path
        if (firstFilePath.length > 0 || firstFolderPath.length > 0) {
          dispatch(
            setFolderPath(
              firstFilePath.length > 0 ? firstFilePath : firstFolderPath
            )
          );
        } else {
          // If no files or folders match, retain the current folder path
          dispatch(
            setFolderPath(
              currentFolderId ? folderPath : [{ id: 0, name: "Home" }]
            )
          );
        }
      }
    } catch (error) {
      console.error("Error during search:", error);

      dispatch(setFolderPath([{ id: 0, name: "Home" }]));
    }
  };


  const getFiles = async (folderId = null, clientId = null) => {
    setdataLoad(true);
    try {
      const files = await FilesService.getFile({
        search: "",
        page: 1,
        folderId,
        clientId: clientId,
        sortBy: "created_at",
        sortDirection: "desc",
      });
      if (files) {
        setfiles(files);

        dispatch(setFolderPath(files.folderPath));

        if (folderId) {
          navigate(`?folderId=${folderId}`);
        } else {
          navigate(location.pathname);
        }
      }
      setdataLoad(false);
    } catch (err) {
      console.log(err);
    }
  };

  
  const folderDetails = async(id,name) => {
    dispatch(appendToFolderPath({ id,name }));
    getFiles(id);
  };


  const getTrashedFile = async () => {
    const data = {
      search: searchKeyword,
      page: 1,
      sortBy: sortBy,
      sortDirection: sortDirection,
    };
    try {
      const trashedFile = await FilesService.getTrashaedFile(data);
      // console.log(trashedFile)
      settrashedFile(trashedFile.files);

      // setcurrentPage(trashedFile.currentPage);
      // setTotalPage(trashedFile.totalPages);
    } catch (err) {
      settrashedFile([]);
      console.log(err);
    }
  };

  useEffect(() => {
    if (!showTrashed) {
      const params = new URLSearchParams(location.search);
      const folderId = params.get("folderId");

      if (folderId) {
        getFiles(folderId, null);
      } else {
        getFiles();
      }
    } else {
      getTrashedFile();
    }
  }, [location.search, showTrashed]);

  const handleBreadcrumbClick = (index) => {
    dispatch(removeFromFolderPath(index));
    const targetFolder = folderPath[index];
    if (targetFolder && targetFolder.id !== 0) {
      getFiles(targetFolder.id);
    } else {
      getFiles();
    }
  };

  const handleResetPath = () => {
    dispatch(resetFolderPath());
    getFiles();
  };


  const handleSelectChange = (event) => {
    const clientid = event.target.value;
    if (clientid == "all") {
      if (currentFolderId) {
        getFiles(currentFolderId, null);
      } else {
        getFiles();
      }
    } else if (currentFolderId) {
      getFiles(currentFolderId, clientid);
    } else {
      getFiles(null, clientid);
    }
  };


  
return (
    <Default pageTitle="Documents">
      <div className="documents card w-100">
        <div className="card-body">
          <div className=" d-flex flex-lg-row flex-column justify-content-between align-items-center ">
            {/* <h5 className="heading">{showTrashed ? "Trashed" : "Documents"}</h5>{" "} */}

            <BreadcrumbNavigation
              folderPath={folderPath}
              handleBreadcrumbClick={handleBreadcrumbClick}
              handleResetPath={handleResetPath}
            />
            {/* Dynamic heading */}
            <div className=" documents__toolbar d-flex justify-content-between mb-3 ">
              {/* <div className="d-flex"> */}
              <div className="company-filter">
                <select
                  className="form-select form-select-sm"
                  aria-label="Default select example"
                  onChange={handleSelectChange}
                >
                  <option selected disabled>
                    By Corporation
                  </option>
                  <option value="all">All</option>

                  {clientDetails.map((client) => (
                    <option key={client.id} value={client.id}>
                      {client.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="ms-2">
                <input
                  type="search"
                  placeholder="Search"
                  onChange={(e) => handleChangeSearch(e)}
                  style={{ boxShadow: "none" }}
                  className="form-control form-control-sm"
                />
              </div>

              {/* </div> */}
              <button
                type="button"
                className="btn btn-sm btn-primary ms-2"
                onClick={uploadFilehandleShow}
              >
                Upload File
              </button>
            </div>
          </div>
          {/* trash and back to file wrapper  */}
          <div className="text-end py-2">
            <a
              href="#"
              className={`${
                showTrashed ? "text-secondary" : "text-danger"
              } trashed-link text-decoration-underline`}
              // style={{
              //   position: "absolute",
              //   bottom: "10px",
              //   right: "20px",
              //   zIndex: "1000",
              // }}
              onClick={(e) => {
                e.preventDefault();
                setShowTrashed(!showTrashed);
                dispatch(resetFolderPath());
              }}
            >
              {showTrashed ? "Back to Files" : "Trashed Files"}
            </a>
          </div>
          <div className="documents__content">
            {!showTrashed &&
              !dataLoad &&
              files?.files?.total === 0 &&
              files?.subfolders?.total === 0 && (
                <div className="text-center text-muted">Data not found</div>
              )}

            {!showTrashed &&
              !dataLoad &&
              (files?.files?.data?.length > 0 ||
                files?.subfolders?.total > 0) && (
                <Documents_files
                  responseData={files}
                  getFiles={getFiles}
                  rootFolders={rootFolder}
                  folderDetails={folderDetails}
                />
              )}
            {showTrashed && (
              <Documents_trashed
                trashedFile={trashedFile}
                getTrashedFile={getTrashedFile}
                closeTrashedView={() => setShowTrashed(false)} // Pass function to close trash view
              />
            )}
          </div>

          {/* <a
            href="#"
            className={`${
              showTrashed ? "text-secondary" : "text-danger"
            } trashed-link text-decoration-underline`}
            style={{
              position: "absolute",
              bottom: "10px",
              right: "20px",
              zIndex: "1000",
            }}
            onClick={(e) => {
              e.preventDefault();
              setShowTrashed(!showTrashed);
              dispatch(resetFolderPath());
            }}
          >
            {showTrashed ? "Back to Files" : "Trashed Files"}
          </a> */}
        </div>
      </div>

      <Modal
        className="modal-lg p-0 m-0"
        show={showUploadFile}
        onHide={uploadFilehandleClose}
        animation={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Upload File(s)
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="m-0">
          <Document_file_Upload
            uploadFilehandleClose={uploadFilehandleClose}
            getFiles={getFiles}
          />
        </Modal.Body>
      </Modal>

      <Modal
        className="modal-lg p-0 m-0"
        show={showViewFile}
        onHide={uploadViewhandleClose}
        animation={true}
      >
        <Modal.Body>
          <View_Approved
            uploadFilehandleClose={uploadViewhandleClose}
            selectedFileUrl={selectedFileUrl}
          />
        </Modal.Body>
      </Modal>
    </Default>
  );
};

export default Documents;
