import axios from 'axios';
import Cookies from 'js-cookie';
const API_URL = `${process.env.REACT_APP_API_URL}/api/clientportal/v1/auth`;



  const setToken=(token)=>{
    Cookies.set('admone', JSON.stringify(token), { 
      expires: 1 / 12, 
      // expires: 15 / 1440,
      path: '/',
      // domain: process.env.REACT_APP_COOKIE_URL || undefined,
      // secure: secureFlag,  // Set secure flag
    
    }); 
  }

const setUserDetails = (res) => {
  
    const id = res.data.user.user.id;
    const name = res.data.user.user.name;
    const email = res.data.user.user.email;
    const phone = res.data.user.user.phone;


    // const client = res.data.user.user.clients;
    const acesssToken = res.data.user.token.access.token;
    const refreshToken = res.data.user.token.refresh.token;
    const middleName = res.data.user.user.surname;
     localStorage.setItem("userName", name);
    localStorage.setItem("middleName", middleName);
    localStorage.setItem("email", email);
    localStorage.setItem("phone", phone);


    localStorage.setItem("userId", id);
    // localStorage.setItem("client", client);
    //  localStorage.setItem("token", acesssToken);
    // localStorage.setItem("refreshToken",refreshToken);
 
  // setAccessToken(acesssToken);
  // setRefreshToken(refreshToken);

  };
  
  const login = async (data ) => {
   const res = await axios.post(API_URL + "/login",data)

  //  console.log("res",res)
  const tokens = {
    accessToken: res.data.user.token.access.token,
    refreshToken:  res.data.user.token.refresh.token
  };
      setToken(tokens)
    setUserDetails(res);
  
    return res;

} 


const logout = async ( ) => {
  localStorage.removeItem("userName");
  localStorage.removeItem("userId");
  localStorage.removeItem("client");
  // localStorage.removeItem("token");

Cookies.remove('admone', { path: '/' });
   return Promise.resolve();

} 



 const RefreshToken = async (data ) => {
    const res = await axios.post(API_URL + "/refreshToken",data)
    

    const tokens = {
      accessToken: res.data.token.access.token,
      refreshToken:  res.data.token.refresh.token
    };
        setToken(tokens)
     
     return res;
 
 }


const LoginAuth={
    
    login,RefreshToken,logout

}

export default LoginAuth