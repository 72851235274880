


import { configureStore } from '@reduxjs/toolkit';

import clientReducer from '../feature/client.slice';
import chatReducer from '../feature/chat.slice';
import messageReducer from '../feature/message.slice';
import loadingReducer from '../feature/loading.slice';
import folderReducer  from '../feature/folderPath.slice'


export const store = configureStore({
  reducer: {
  client:clientReducer,
  chat:chatReducer,
  message:messageReducer,
  loading:loadingReducer,
  folder: folderReducer
  }
});
