import axios from 'axios';
import axiosInstance from '../utils/axiosInstance';
const API_URL = `${process.env.REACT_APP_API_URL}/api/clientportal/v1/file/`;

const UploadFiles = async (data ) => {

    const res = await axiosInstance.post(API_URL + "upload",data,{
        headers: {
          'Content-Type': 'multipart/form-data',
        },})

    return res.data;

}

const getFile=async(data)=>{
    const res = await axiosInstance.post(API_URL+"getFile",data,{
        headers: {
          'Content-Type': 'application/json',
        },})

    return res.data;
}


const FilterFileFolder=async(data)=>{
  const res = await axiosInstance.post(API_URL+"Filter",data,{
      headers: {
        'Content-Type': 'application/json',
      },})

  return res.data;
}

const getFoldersDetails=async(data)=>{
  const res = await axiosInstance.post(API_URL+"folder_details",data,{
      headers: {
        'Content-Type': 'application/json',
      },})

  return res.data;
}


const savefile=async(data)=>{
    const res = await axiosInstance.post(API_URL+"save",data,{
        headers: {
          'Content-Type': 'application/json',
        },})

    return res.data;
}





const getTrashaedFile=async(data)=>{
    const res = await axiosInstance.post(API_URL+`getTrash`,data)

    return res.data;
}

const DeleteFile=async(id)=>{
    const res = await axiosInstance.delete(API_URL+`${id}/delete`,{
        headers: {
          'Content-Type': 'application/json',
        },})

    return res.data;
}

const RecoverFile=async(id)=>{
    const res = await axiosInstance.patch(API_URL+`${id}/recover`,{
        headers: {
          'Content-Type': 'application/json',
        },})

    return res.data;
}


const approvedFile=async(id,data)=>{
    const res = await axiosInstance.patch(API_URL+`${id}/approvedFile`,data,{
        headers: {
          'Content-Type': 'application/json',
        },})

    return res.data;
}



const filePreview = async (id) => {
    
    const res = await axiosInstance.get(API_URL + `${id}/previewFile`, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json', 
      },
    });
  
    return res; 
  };

const FilesService={
    
    UploadFiles,getFile,getTrashaedFile,DeleteFile,RecoverFile,approvedFile,savefile,filePreview,getFoldersDetails,FilterFileFolder

}

export default FilesService